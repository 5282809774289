export const OrderStatus = {
  DELETED: -99,
  TEMPORARY: -1,
  NEW: 0,
  SCHEDULED: 2,
  IN_PROGRESS: 3,
  COMPLETED: 4,
  CANCELLED: 6,
  ISSUE: 7,
  NOT_COMPLETED: 9,
};
