
import gsap from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { mapActions, mapGetters, mapState } from 'vuex';

// has to be with the trailing slash
const vue3Routes = ['/orders', '/contracts', '/reporting', '/organisations', '/routes'];

export default {
  name: 'LayerDefault',
  middleware: ['auth-router'],
  data() {
    return {
      dataLoaded: false,
      userLoggedInOnLoad: false,
      // this value is needed to cause reload of the logistics-orders app.
      // it can be removed after "Add completed order" functionality is moved into the inner vue3 app
      triggerKey: 0,
    };
  },

  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.loggedIn,
      sideBarOpen: (state) => state.app.sideBarOpen,
    }),
    ...mapGetters(['currentOrgLogisticId']),

    isSideBarOpen() {
      if (['index', 'index-forgot-password'].includes(this.$route.name)) {
        return '';
      }

      return 'p-6 lg:ml-9 lg:pl-80';
    },
    isVue3AppActive() {
      for (const route of vue3Routes) {
        if (this.$route.path.startsWith(route)) {
          return true;
        }
      }
      return false;
    },
    isTopNavVisible() {
      return this.isLoggedIn;
    },
    baseUrl() {
      return `${this.$axios.defaults.baseURL}/api`;
    },
    currentRoute() {
      return this.$route;
    },
  },

  watch: {
    currentRoute(route) {
      // needed to programmatically refresh the router inside the vue3 app
      // on top level url change
      if (this.isVue3AppActive) {
        window?.vue3LogistictManagerRouter?.push({
          path: route.path,
          query: route.query,
        });
      }
    },
    isLoggedIn(value) {
      if (!value) {
        this.userLoggedInOnLoad = value;
      }
    },
  },

  async mounted() {
    gsap.registerPlugin(Draggable, InertiaPlugin);

    if (this.isLoggedIn) {
      this.userLoggedInOnLoad = true;
      this.loadUser();
      await this.setDefaultOrgLogistic();
      await this.getStreams();
      const orgLogisticId = this.$route.query.org_logistic_id;
      if (orgLogisticId) {
        this.getOrgLogisticById(orgLogisticId);
      }
      if (this.$route.path === '/') this.$router.push('/routes');
    }
    this.dataLoaded = true;
    window.vue3AppLogout = this.logout;
  },
  methods: {
    ...mapActions(['loadUser', 'setDefaultOrgLogistic', 'getStreams', 'getOrgLogisticById', 'logout']),
  },
};
