export const namespaced = false;
export const SET_ORG_LOGISTIC_ORDERS = 'setOrgLogisticOrders';
export const SET_ORG_LOGISTIC_ORDERS_PAGINATION = 'setOrgLogisticOrdersPagination';

export const state = () => ({
  orgLogisticOrders: [],
  orgLogisticsOrdersPagination: {},
});

export const mutations = {
  [SET_ORG_LOGISTIC_ORDERS](state, orders) {
    state.orgLogisticOrders = orders;
  },
  [SET_ORG_LOGISTIC_ORDERS_PAGINATION](state, paginationMetadata) {
    state.orgLogisticsOrdersPagination = paginationMetadata;
  },
};

export const getters = {
  orders: (state) => {
    return state.orgLogisticOrders;
  },
  pagination: (state) => {
    return state.orgLogisticsOrdersPagination;
  },
};
export const actions = {
  async getOrders({ commit, rootGetters }, { startDate, endDate, page, limit }) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    const response = await this.$axios.get(`/api/fleet/logistics/${orgLogisticId}/orders`, {
      params: { page, limit, date_start: startDate, date_end: endDate },
    });
    commit(SET_ORG_LOGISTIC_ORDERS_PAGINATION, response.data.pagination);
    commit(SET_ORG_LOGISTIC_ORDERS, response.data.items);
  },
  async getOrderDetails({ rootGetters }, orderId) {
    const orgLogisticId = rootGetters.currentOrgLogisticId;
    const response = await this.$axios.get(`/api/fleet/logistics/${orgLogisticId}/orders/${orderId}`);
    return response.data;
  },
};
