function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

export default ({ app }) => {
  // Every time the route changes (fired on initialization too)
  app.router.beforeEach((to, from, next) => {
    if (!hasQueryParams(to) && hasQueryParams(from)) {
      // Keep query parameters
      next({ name: to.name, query: from.query });
    } else {
      next();
    }
  });
};
