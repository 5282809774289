
import { mapActions, mapGetters, mapState } from 'vuex';

import { ExternalLinks, Locales, OrderProcessingTypes } from '~/configs/consts';

import { NON_OPTIMIZED_USER_ROUTES, OPTIMIZED_USER_ROUTES } from './routes';

export default {
  name: 'TheSidebar',
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.loggedIn,
      sideBarOpen: (state) => state.app.sideBarOpen,
    }),
    ...mapGetters(['currentOrgLogistic', 'isSeenonsUser']),
    isLogisticsOptimized() {
      return this.currentOrgLogistic?.order_processing_type === OrderProcessingTypes.OPTIMIZED;
    },
    routes() {
      return this.isLogisticsOptimized ? OPTIMIZED_USER_ROUTES : NON_OPTIMIZED_USER_ROUTES;
    },
    navLinks() {
      // if feature flags logic is needed, it should be added here
      return this.routes;
    },
    logisticsPartnerLink() {
      return this.$i18n.locale === Locales?.NL
        ? ExternalLinks.LOGISTICS_PARTNER_NL
        : ExternalLinks.LOGISTICS_PARTNER_EN;
    },
    githubRefName() {
      return process.env.githubRefName ?? '-';
    },
    apiVersion() {
      return localStorage.getItem('x-coreapi-version') ?? '-';
    },
    isDev() {
      return process.env.environmentName === 'dev';
    },
  },
  methods: {
    ...mapActions({
      toggleSidebar: 'app/toggleSidebar',
    }),
  },
};
