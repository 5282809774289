import Vue from 'vue';

import * as Honeybadger from '@honeybadger-io/js';

Vue.config.errorHandler = function (error, vm, info) {
  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in. Only available in 2.2.0+
  Honeybadger.setContext({
    component: {
      name: vm?._name,
      node: vm?.$el?.nodeName,
      class: vm?.$el?._prevClass,
    },
    vueInfo: info,
  });
  // eslint-disable-next-line no-console
  console.error(error);
  Honeybadger.notify(error);
};
