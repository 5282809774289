
import { mapActions, mapGetters } from 'vuex';

import { OrderProcessingTypes } from '~/configs/consts';

export default {
  name: 'OrgLogisticsDropdown',
  computed: {
    ...mapGetters(['orgLogistics', 'currentOrgLogistic', 'currentOrgLogisticId']),
    orgLogisticsNamesItems() {
      return this.orgLogistics.map((orgLogistic, index) => ({
        id: index,
        name: orgLogistic.name,
      }));
    },
    currentOrgLogisticIndex() {
      const index = this.orgLogistics.findIndex(
        (orgLogistic) => this.currentOrgLogisticId === orgLogistic.org_logistic_id,
      );
      return index >= 0 ? index : 0;
    },
  },
  async mounted() {
    window.vue3OnOrgLogisticChange = this.onOrgLogisticChange;
  },
  methods: {
    ...mapActions(['getOrgLogisticById']),
    async selectedOrgLogistic(index) {
      await this.onOrgLogisticChange(this.orgLogistics[index].org_logistic_id);
    },
    async onOrgLogisticChange(orgLogisticId) {
      await this.getOrgLogisticById(orgLogisticId);
      if (this.$route.params.id) this.$router.back();
      if (this.currentOrgLogistic?.order_processing_type !== OrderProcessingTypes.OPTIMIZED) {
        this.$router.push('/orders');
      }
    },
  },
};
