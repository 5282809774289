import DOMPurify from 'dompurify';

export default (_, inject) => {
  const sanitize = (dirtyHtml) => {
    if (!dirtyHtml) {
      return '';
    }
    return DOMPurify.sanitize(dirtyHtml);
  };
  inject('sanitize', sanitize);
};
