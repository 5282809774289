export const state = () => ({
  namespaced: true,
  sideBarOpen: false,
});

export const mutations = {
  toggleSidebar(state) {
    state.sideBarOpen = !state.sideBarOpen;
  },
};

export const getters = {
  sideBarOpen: (state) => {
    return state.sideBarOpen;
  },
};
export const actions = {
  toggleSidebar({ commit }) {
    commit('toggleSidebar');
  },
};
