// add components you want to register globally.
// Warning: only use this for small components without much logic as registering a component
// globally can create circular dependencies in some cases. For example when you use the store
// in your component

export default {
  SdIcon: () => import('~/components/common/SdIcon.vue'),
  SdField: () => import('~/components/form/SdField.vue'),
  SdInput: () => import('~/components/form/SdInput.vue'),
};
