
import ClickOutside from 'vue-click-outside';
import VueTypes from 'vue-types';

export default {
  name: 'DropdownBase',
  directives: {
    ClickOutside,
  },
  props: {
    items: VueTypes.array.isRequired,
    selectedItemIndex: VueTypes.number,
    isForm: VueTypes.bool.def(false),
    selectText: VueTypes.string.def(''),
    disabledItems: VueTypes.array.def([]),
  },
  data() {
    return {
      isOpen: false,
      isItemSelected: false,
    };
  },
  computed: {
    selectPlaceholderText() {
      return this.isForm && this.selectedItemIndex === null;
    },
    defaultText() {
      return this.selectPlaceholderText ? this.selectText : this.items?.[this.selectedItemIndex]?.name;
    },
  },
  mounted() {
    // prevent click outside event with popupItem.
    this.popupItem = this.$el;
  },
  methods: {
    hideDropdownItems() {
      this.isOpen = false;
    },
    selectDropdownItem(index) {
      this.$emit('change', index);
      this.isItemSelected = true;
      this.hideDropdownItems();
    },
    disableItem(index) {
      const isCurrentElement = index === this.selectedItemIndex;
      return !isCurrentElement && this.disabledItems.includes(index);
    },
    jumpToItem(event) {
      // Internal solution to facilitate the navigation of the huge dropdowns
      const itemIndex = this.items.findIndex((item) => item.name.charAt(0).toLowerCase() === event.key);
      const itemToShow = this.$refs[`item${itemIndex}`];

      if (itemToShow && itemToShow[0]) {
        itemToShow[0].scrollIntoView();
      }
    },
  },
};
