import { ValidationObserver, ValidationProvider, extend, localize, setInteractionMode } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import Vue from 'vue';

export default function ({ app }) {
  Object.keys(rules).forEach((ruleName) => {
    extend(ruleName, rules[ruleName]);
  });
  /* eslint-enable */

  setInteractionMode('eager');

  localize(app.i18n.locale, {
    messages: {
      email: app.i18n.t('input_right_email'),
      required: app.i18n.t('input_is_required'),
    },
  });

  // Custom extends
  extend('samevalue', {
    params: ['target'],
    validate(value, { target }) {
      return value === target;
    },
    message: app.i18n.t('validation.password_confirmation_doesnt_match'),
  });

  extend('password', (value) => {
    if (value.match(/^.{8,}$/)) {
      return true;
    }

    return app.i18n.t('password_should_be_txt');
  });

  extend('yyyymmdd', (value) => {
    const REGEX_YYYY_MM_DD = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

    if (value.match(REGEX_YYYY_MM_DD)) {
      return true;
    }
    return app.i18n.t('format_YYY_MM_DD_txt');
  });

  extend('hhmm', (value) => {
    const HH_MM = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return value.match(HH_MM) ? true : app.i18n.t('time_is_not_correct');
  });

  Vue.component('ValidationObserver', ValidationObserver);
  Vue.component('ValidationProvider', ValidationProvider);
}
