export const NON_OPTIMIZED_USER_ROUTES = [
  {
    to: 'reporting',
    icon: 'insights',
    name: 'reporting',
  },
  {
    to: 'contracts',
    icon: 'contracts',
    name: 'contracts',
  },
  {
    to: 'orders',
    icon: 'orders',
    name: 'orders',
  },
];

export const OPTIMIZED_USER_ROUTES = [
  {
    to: 'routes',
    icon: 'routes',
    name: 'routes',
  },
  ...NON_OPTIMIZED_USER_ROUTES,
];
