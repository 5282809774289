import format from 'date-fns/format';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import enGB from 'date-fns/locale/en-GB';
import nl from 'date-fns/locale/nl';

import { Locales } from '~/configs/consts';
import padNumber from '~/utils/padNumber';

export function getLocalizedMonth(dateString, loc) {
  let locale = nl;
  switch (loc) {
    case Locales.NL:
      locale = enGB;
      break;
    default:
      locale = nl;
  }
  return format(new Date(dateString), 'dd MMMM yyyy', { locale });
}

export function formatDateToTime(dateString) {
  const date = new Date(dateString);
  const hours = getHours(date);
  const min = getMinutes(date);
  return `${padNumber(hours, 2)}:${padNumber(min, 2)}`;
}
