import { MIN_ITEMS_PER_PAGE } from '~/utils/paginationConfigurations';

export const namespaced = false;
export const SET_ORG_LOGISTIC_ROUTES = 'setOrgLogisticsRoutes';
export const SET_ORG_LOGISTIC_ROUTES_PAGINATION = 'setOrgLogisticRoutesPagination';
export const SET_TABLE_ROUTE = 'setTableRoute';

export const state = () => ({
  orgLogisticRoutes: [],
  orgLogisticRoutesPagination: {},
  selectedDashRoute: null,
});

export const mutations = {
  [SET_ORG_LOGISTIC_ROUTES](state, orgLogisticRoutes) {
    state.orgLogisticRoutes = orgLogisticRoutes;
  },
  [SET_TABLE_ROUTE](state, selectedDashRoute) {
    state.selectedDashRoute = selectedDashRoute;
  },
  [SET_ORG_LOGISTIC_ROUTES_PAGINATION](state, orgLogisticRoutesPagination) {
    state.orgLogisticRoutesPagination = orgLogisticRoutesPagination;
  },
};

export const getters = {
  selectedDashRoute: (state) => {
    return state.selectedDashRoute;
  },
  orgLogisticRoutes: (state) => {
    return state.orgLogisticRoutes;
  },
  routesPagination: (state) => {
    return state.orgLogisticRoutesPagination.total_items || 0;
  },
  route: (state) => (id) => {
    if (id) return state.orgLogisticRoutes.find((route) => route.route_id === id);
  },
};

export const actions = {
  async getOrgLogisticRoutes({ commit, rootGetters }, { page = 1, limit = MIN_ITEMS_PER_PAGE }) {
    // Get all the routes assigned to an OrgLogistic
    const org_logistic_id = rootGetters.currentOrgLogisticId;
    try {
      const response = await this.$axios.get(`api/fleet/logistics/${org_logistic_id}/routes`, {
        params: {
          page,
          limit,
        },
      });
      commit(SET_ORG_LOGISTIC_ROUTES, response.data.items);
      commit(SET_ORG_LOGISTIC_ROUTES_PAGINATION, response.data.pagination);
    } catch (error) {
      this.$toast.global.customError(`Something went wrong: ${error}`);
    }
  },
};
