import { render, staticRenderFns } from "./TheSidebar.vue?vue&type=template&id=1c0a5c27&scoped=true&"
import script from "./TheSidebar.vue?vue&type=script&lang=js&"
export * from "./TheSidebar.vue?vue&type=script&lang=js&"
import style0 from "./TheSidebar.vue?vue&type=style&index=0&id=1c0a5c27&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c0a5c27",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SdIcon: require('/home/runner/work/seenons-fe-logistics/seenons-fe-logistics/apps/logistics-manager/components/common/SdIcon.vue').default,SidebarNavLink: require('/home/runner/work/seenons-fe-logistics/seenons-fe-logistics/apps/logistics-manager/components/layout/sidebar/SidebarNavLink.vue').default})
