
import ClickOutside from 'vue-click-outside';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheMenu',
  directives: {
    ClickOutside,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters(['userFirstName', 'userRoles']),
    hasAdminOrganizations() {
      return this.userRoles.filter((org) => org.role === 'admin').length > 0;
    },
  },
  mounted() {
    // prevent click outside event with popupItem.
    this.popupItem = this.$el;
  },
  methods: {
    ...mapActions({
      logout: 'logout',
    }),
    closeMenu() {
      this.showMenu = false;
    },
    openUserManagementPage() {
      this.showMenu = false;
      this.$router.push('/organisations');
    },
  },
};
