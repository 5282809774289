import { render, staticRenderFns } from "./SidebarNavLink.vue?vue&type=template&id=613ee1c7&scoped=true&"
import script from "./SidebarNavLink.vue?vue&type=script&lang=js&"
export * from "./SidebarNavLink.vue?vue&type=script&lang=js&"
import style0 from "./SidebarNavLink.vue?vue&type=style&index=0&id=613ee1c7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "613ee1c7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SdIcon: require('/home/runner/work/seenons-fe-logistics/seenons-fe-logistics/apps/logistics-manager/components/common/SdIcon.vue').default})
