/* eslint-disable no-empty-pattern */
const namespace = 'user';
export const SET_USER_DATA = `${namespace}/setUserData`;
export const CLEAR_USER_DATA = `${namespace}/clearUserData`;

export const namespaced = false;

export const state = () => ({
  userData: null,
});

export const mutations = {
  [SET_USER_DATA](state, data) {
    state.userData = data;
  },
  [CLEAR_USER_DATA](state) {
    state.userData = null;
  },
};

export const getters = {
  userFirstName: (state) => {
    if (state.userData) {
      return `${state.userData.first_name}`;
    }
  },
  isSeenonsUser: (state) => {
    if (state.userData) {
      return state.userData.email.endsWith('@seenons.com');
    }
  },
  userRoles: (state) => {
    if (state.userData) {
      return state.userData.roles || [];
    }
  },
  userAdminOrganizations: (state) => {
    if (state.userData?.roles) {
      return state.userData.roles.filter((org) => org.role === 'admin');
    }
  },
};

export const actions = {
  async setLogin({ dispatch }, credentials) {
    await this.$auth.loginWith('local', { data: credentials });
    dispatch('loadUser');
  },
  loadUser({ commit }) {
    if (this.$auth.loggedIn) {
      commit(SET_USER_DATA, this.$auth.user);
      const supportedLocales = this.app.i18n.localeCodes;
      const currentLocale = supportedLocales.includes(this.$auth.user.locale) ? this.$auth.user.locale : 'en-gb';
      this.$i18n.setLocale(currentLocale);
    }
  },
  async logout({ commit }) {
    try {
      await this.$auth.logout();
      commit(CLEAR_USER_DATA);
      commit('organizations/clearOrgId', null, { root: true });
      commit('orgLogistics/clearOrgLogistics', null, { root: true });
    } catch (error) {
      this.$toast.global.customError(`Logout error: ${error}`);
    }
  },
  async setForgotPassword({}, email) {
    return await this.$axios.$post(`/api/fleet/auth/forgot`, { email });
  },
};
