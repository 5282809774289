import * as Honeybadger from '@honeybadger-io/js';

Honeybadger.configure({
  apiKey: process.env.honeyBadgerAPIKey,
  environment: process.env.environmentName,
  developmentEnvironments: ['local', 'test', 'development'],
  revision: process.env.githubRefName,
});

// Add app version to Honeybadger error context
Honeybadger.setContext({
  version: process.env.version,
});

Honeybadger.beforeNotify(function (notice) {
  // ignore redirect errors from the navigation guard
  if (notice.message.startsWith('Redirected when going from')) {
    return false;
  }
  if (notice.isAxiosError) {
    const { response } = notice;
    // ignore 400 errors from the login page
    // TODO remove /api/auth
    if (
      response.status === 400 &&
      (response.config.url.startsWith('/api/auth/') || response.config.url.startsWith('/api/fleet/auth/'))
    ) {
      return false;
    }
  }
});
