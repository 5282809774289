
export default {
  name: 'BaseIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    rotation: {
      type: String,
      default: '0deg',
    },
    size: {
      type: String,
      default: '24px',
    },
  },
};
