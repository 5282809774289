
import VueTypes from 'vue-types';

export default {
  name: 'SdIcon',
  props: {
    sdSrc: VueTypes.string,
    freeSize: VueTypes.bool.def(false),
    colorStroke: VueTypes.string,
    colorFill: VueTypes.string,
  },
};
