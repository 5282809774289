const namespace = 'streams';

export const namespaced = false;
export const SET_AVAILABLE_STREAMS = `${namespace}/setAvailableStreams`;

export const state = () => ({
  streams: [],
});

export const mutations = {
  [SET_AVAILABLE_STREAMS](state, streams) {
    state.streams = streams;
  },
};

export const getters = {
  streams: (state) => {
    return state.streams;
  },
  getStreamById: (state) => (streamId) => {
    if (!streamId) return null;

    return state.streams.find((stream) => stream.stream_product_id === streamId);
  },
};

export const actions = {
  async getStreams({ commit }) {
    const data = await this.$axios.$get('/api/marketplace/streams');
    commit(SET_AVAILABLE_STREAMS, data);
    return data.items;
  },
};
