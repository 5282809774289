import Vue from 'vue';

import { formatDateToTime } from '~/utils';
import { parseDate } from '~/utils/dates.js';

Vue.filter('formatDateInDDMMYYYY', function (date) {
  if (!date) {
    return '';
  }
  return parseDate(date);
});

Vue.filter('formatDateToTimeInHHMM', function (date) {
  if (!date) {
    return '';
  }
  return formatDateToTime(date);
});

Vue.filter('formatRow', function (value) {
  if (!value) {
    return '-';
  }
  return value;
});

function dig(target, ...keys) {
  let digged = target;
  for (const key of keys) {
    if (typeof digged === 'undefined' || digged === null) {
      return undefined;
    }
    if (typeof key === 'function') {
      digged = key(digged);
    } else {
      digged = digged[key];
    }
  }
  return digged;
}

Vue.filter('extractField', function () {
  const [value, ...fieldPath] = arguments;

  if (!value) {
    return null;
  }

  return dig(value, ...fieldPath);
});
