import * as Honeybadger from '@honeybadger-io/js';

export default function ({ $axios, store }) {
  $axios.onResponse((response) => {
    const CORE_API_HEADER = 'x-coreapi-version';

    if (response.headers[CORE_API_HEADER]) {
      localStorage.setItem(CORE_API_HEADER, response.headers[CORE_API_HEADER]);
    }
  });
  $axios.onError((error) => {
    // EXPLAINME: Overwrite body request to not expose sensitive data such as passwords or token
    const errorRequestDetails = {
      ...error.config,
      data: null,
      headers: {
        ...error.config?.headers,
        Authorization: null,
      },
      response: {
        ...error.response?.data,
      },
    };

    Honeybadger.setContext({
      request_details: errorRequestDetails,
    });

    const serverErrorRegexp = /^5\d{2}$/;
    if (!serverErrorRegexp.test(error.response?.status) && ![422, 401, 404].includes(error.response?.status)) {
      Honeybadger.notify(error);
    }

    return Promise.reject(error);
  });

  $axios.interceptors.request.use(function (config) {
    const jwtToken = store.$auth?.token;
    if (jwtToken) {
      $axios.setToken(jwtToken, 'Bearer');
    }

    return config;
  });
}
