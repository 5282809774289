
import VueTypes from 'vue-types';
import { mapActions } from 'vuex';

export default {
  name: 'SidebarNavLink',
  props: {
    to: VueTypes.string.def('/'),
    icon: VueTypes.string.isRequired,
    name: VueTypes.string.isRequired,
  },
  methods: {
    ...mapActions({
      toggleSidebar: 'app/toggleSidebar',
    }),
  },
};
