export const namespace = 'orgLogistics';
export const SET_ORG_LOGISTICS = `${namespace}/setLogistics`;
export const SET_ORG_LOGISTIC = `${namespace}/setOrgLogistic`;
export const CLEAR_ORG_LOGISTICS = `${namespace}/clearOrgLogistics`;
export const namespaced = false;

export const state = () => ({
  orgLogistics: [],
  currentOrgLogistic: {},
});

export const getters = {
  orgLogistics: (state) => {
    return state.orgLogistics;
  },
  currentOrgLogistic: (state) => {
    return state.currentOrgLogistic;
  },
  currentOrgLogisticId: (state) => {
    if (state.currentOrgLogistic) {
      return state.currentOrgLogistic.org_logistic_id;
    }
  },
  currentOrgLogisticName: (state) => {
    if (state.currentOrgLogistic) {
      return state.currentOrgLogistic.name;
    }
  },
};

export const mutations = {
  [SET_ORG_LOGISTICS](state, orgLogistics) {
    state.orgLogistics = orgLogistics;
  },
  [SET_ORG_LOGISTIC](state, orgLogistic) {
    state.currentOrgLogistic = orgLogistic;
  },
  [CLEAR_ORG_LOGISTICS](state) {
    state.currentOrgLogistic = null;
  },
};

export const actions = {
  async setDefaultOrgLogistic({ commit, dispatch, rootGetters }) {
    const data = await dispatch('getOrgLogistics');
    const currentOrgLogisticId = rootGetters.currentOrgLogisticId;

    let defaultOrgLogistic;
    if (data) {
      if (currentOrgLogisticId) {
        defaultOrgLogistic = data.find((orgLogistic) => orgLogistic.org_logistic_id === currentOrgLogisticId);
      } else {
        defaultOrgLogistic = data[0];
      }
    }

    commit(SET_ORG_LOGISTIC, defaultOrgLogistic);
  },
  async getOrgLogistics({ commit }) {
    const { data } = await this.$axios.get('/api/fleet/logistics');
    commit(SET_ORG_LOGISTICS, data);
    return data;
  },

  async getOrgLogisticById({ commit }, orgLogisticId) {
    const response = await this.$axios.get(`/api/fleet/logistics/${orgLogisticId}`);
    commit(SET_ORG_LOGISTIC, response.data);
    return response;
  },
};
